import React from 'react';
import {
    Switch,
    Route,
} from 'react-router-dom';

import { Routes } from './constants';
import HomePage from './pages/home/HomePage';
import CoursesPage from './pages/courses/CoursesPage';
import ExamsPage from './pages/exams/ExamsPage';
import FaqPage from './pages/faq/FaqPage';
import SchoolPage from './pages/school/SchoolPage';
import TermsPage from './pages/terms/TermsPage';
import PrivacyPage from './pages/privacy/PrivacyPage';
import BlogListPage from './pages/blog/ListPage';
import BlogSinglePage from './pages/blog/SinglePage';

const Routing = () => {
    return (
        <Switch>
            {/* <div className="App">
            <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <p>
                Edit 1 <code>src/App.js</code> and save to reload.
            </p>
            <a
                className="App-link"
                href="https://reactjs.org"
                target="_blank"
                rel="noopener noreferrer"
            >
                Learn React
            </a>
            </header>
        </div> */}
            <Route exact path={Routes.HOME}>
                <HomePage />
            </Route>
            <Route path={Routes.COURSES}>
                <CoursesPage />
            </Route>
            <Route path={Routes.EXAMS}>
                <ExamsPage />
            </Route>
            <Route path={Routes.SCHOOL}>
                <SchoolPage />
            </Route>
            <Route path={Routes.FAQ}>
                <FaqPage />
            </Route>
            <Route path={Routes.TERMS}>
                <TermsPage />
            </Route>
            <Route path={Routes.PRIVACY}>
                <PrivacyPage />
            </Route>
            <Route path={Routes.NEWS_SINGLE}>
                <BlogSinglePage />
            </Route>
            <Route path={Routes.NEWS}>
                <BlogListPage />
            </Route>
            <Route>
                <div>
                    Error 404
                </div>
            </Route>
        </Switch>
    );
};

export default Routing;