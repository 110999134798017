import React from 'react';
import { useSelector } from 'react-redux';
import { useMedia } from 'react-use-media';
import classNames from 'classnames';
import {
    Link
} from 'react-router-dom';
import Sticky from 'react-stickynode';

import { Routes, Pages, Breakpoints } from '../../../constants';
// import { adaptPages } from '../../helpers/adapters';

import ArrowLeft from '../../../images/arrow-left.svg';

import Styles from './Breadcrumbs.module.css';
import AppStyles from '../../../App.module.css';

const Breadcrumbs = ({ pathname }) => {
    let pageName = '';
    // const pages = useSelector(state => state.pages.data);
    // const page = adaptPages(pages).find((item)=>item.slug === pathname);
    
    // if (page) {
    //     pageName = page.seoTitle;
    // }
    const news = useSelector(state => state.posts.data);

    const mobile = useMedia({
        maxWidth: Breakpoints.MEDIUM,
    });

    if (pathname === Routes.HOME) {
        return null;
    }

    switch (pathname) {
    case Routes.COURSES:
        pageName = Pages.COURSES;
        break;
    case Routes.EXAMS:
        pageName = Pages.EXAMS;
        break;
    case Routes.SCHOOL:
        pageName = Pages.SCHOOL;
        break;
    case Routes.FAQ:
        pageName = Pages.FAQ;
        break;
    case Routes.PRIVACY:
        pageName = Pages.PRIVACY;
        break;
    case Routes.TERMS:
        pageName = Pages.TERMS;
        break;
    case Routes.NEWS:
        pageName = Pages.NEWS;
        break;
    default:
        if (pathname.includes(Routes.NEWS)) {
            const post = news.find((item)=>item.url === pathname);
            const postTitle = post ? post.title : '-';
            pageName = !mobile ? Pages.NEWS_SINGLE.replace('{{title}}', postTitle) : Pages.NEWS;
        }
        break;
    }
    
    return (
        <Sticky top={60}>
            <div className={ Styles.breadcrumbs }>
                <div className={ classNames(Styles.box, AppStyles.container) }>
                    <Link
                        className={ Styles.back }
                        to={Routes.HOME}>
                        <img src={ ArrowLeft } alt="Go back"/>
                    Voltar
                    </Link>
                    <span>
                        { `INÍCIO > ${pageName}` }
                    </span>
                </div>
            </div>
        </Sticky>
    );
};

export default Breadcrumbs;