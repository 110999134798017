import React from 'react';

import Styles from './BlockLine.module.css';

const BlockLine = ({ title, description }) => {
    return (
        <div className={ Styles.line }>
            <div className={ Styles.title }>
                <span dangerouslySetInnerHTML={{ __html: title }} />
            </div>
            <div className={ Styles.description }>
                <span dangerouslySetInnerHTML={{ __html: description }} />
            </div>
        </div>
    );
};

export default BlockLine;