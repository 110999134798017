import React, { useState, useEffect } from 'react';
import { useMedia } from 'react-use-media';
import { animateScroll } from 'react-scroll';
// import rafScroll from 'raf-scroll';
// import classNames from 'classnames';

import { Breakpoints } from '../../../constants';

import ArrowLeft from '../../../images/arrow-left.svg';

import Styles from './GoTop.module.css';
import AppStyles from '../../../App.module.css';

let last_known_scroll_position = 0;
let ticking = false;

const GoTop = () => {
    const [show, setShow] = useState(false);

    const mobile = useMedia({
        maxWidth: Breakpoints.MEDIUM,
    });

    const handleClick = () => {
        animateScroll.scrollToTop();
    };

    useEffect(() => {
        if (window.innerWidth > Breakpoints.MEDIUM) {
            // https://developer.mozilla.org/en-US/docs/Web/API/Document/scroll_event
            window.addEventListener('scroll', (e) => {
                last_known_scroll_position = window.scrollY;
            
                if (!ticking) {
                    window.requestAnimationFrame(() => {
                        if(last_known_scroll_position > 100) {
                            setShow(true);
                        }
                        else {
                            setShow(false);
                        }
                        ticking = false;
                    });
          
                    ticking = true;
                }
            });
            // TODO remove event
            return ()=>{};
        }
    }, []);

    if (!show || mobile) {
        return null;
    }

    return (
        <div className={ Styles.top }>
            <div className={ AppStyles.container }>
                <div className={ Styles.box }>
                    <a
                        onClick={ handleClick }>
                        <img src={ ArrowLeft } alt="Ir para o topo"/>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default GoTop;