import { combineReducers } from 'redux';
import { createReducer, createAction } from 'redux-starter-kit';

const namespace = 'pages';
const fetchStart = createAction(`${namespace}/FETCH_START`);
const fetchSuccess = createAction(`${namespace}/FETCH_SUCCESS`);
const fetchFail = createAction(`${namespace}/FETCH_FAIL`);

const initialState = {
    loading: false,
    data: [],
    error: null,
};

const data = createReducer(initialState.data, {
    [fetchSuccess]: (state, action) => action.payload,
});

const loading = createReducer(initialState.loading, {
    [fetchStart]: () => true,
    [fetchSuccess]: () => false,
    [fetchFail]: () => false,
});

const error = createReducer(initialState.error, {
    [fetchFail]: (state, action) => action.payload,
});

export const actions = {
    fetchStart,
    fetchSuccess,
    fetchFail,
};

export default combineReducers({
    data,
    loading,
    error,
});
