import React from 'react';
import { scroller } from 'react-scroll';
import {
    NavLink,
    // useLocation,
    useHistory,
} from 'react-router-dom';
// import classNames from 'classnames';

import { Routes } from '../../../../constants';

import Styles from './TopNav.module.css';

const TopNav = () => {
    // const { pathname } = useLocation();
    let history = useHistory();

    function goToHomeContacts() {
        history.push(Routes.HOME);
        setTimeout(() => {
            scroller.scrollTo('contacts', {
                // duration: 1500,
                // delay: 100,
                smooth: true,
                // offset: 50, // Scrolls to element + 50 pixels down the page
            }); 
        }, 100);
    }

    return (
        <div className={ Styles.navigation }>
            <ul className={ Styles.list }>
                <li className={ Styles.listItem }>
                    <NavLink
                        activeClassName={ Styles.active }
                        to={Routes.COURSES}>
                                Cursos
                    </NavLink>
                </li>
                <li className={ Styles.listItem }>
                    <NavLink
                        activeClassName={ Styles.active }
                        to={Routes.EXAMS}>
                                Exames
                    </NavLink>
                </li>
                <li className={ Styles.listItem }>
                    <NavLink
                        activeClassName={ Styles.active }
                        to={Routes.NEWS}>
                                Notícias
                    </NavLink>
                </li>
                <li className={ Styles.listItem }>
                    <NavLink
                        activeClassName={ Styles.active }
                        to={Routes.SCHOOL}>
                                A Escola
                    </NavLink>
                </li>
                <li className={ Styles.listItem }>
                    {/* <Link to={Routes.CONTACTS}>
                                Contacto
                            </Link> */}
                    {/* <ScrollLink
                        activeClass="active"
                        to="contacts"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                    >
                                Contacto
                    </ScrollLink> */}
                    {/* <button
                        onClick={ goToHomeContacts }>
                        Contacto
                    </button> */}
                    <a
                        role="button"
                        tabIndex="0"
                        onClick={ goToHomeContacts }>
                        Contacto
                    </a>
                </li>
            </ul>
        </div>
    );
};

export default TopNav;