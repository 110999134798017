import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import ListItem from './list/ListItem';

import Styles from './ListPage.module.css';
import AppStyles from '../../App.module.css';

const ListPage = () => {
    const news = useSelector(state => state.posts.data);

    return (
        <div className={ classNames(Styles.news, AppStyles.container) }>
            <h2>
                Notícias
            </h2>

            { news.map((post) => (
                <ListItem
                    key={post.id}
                    {...post} />
            )) }
        </div>
    );
};

export default ListPage;