import React from 'react';
import classNames from 'classnames';

import Contacts from '../../shared/components/contacts/Contacts';

import Styles from './SchoolPage.module.css';
import AppStyles from '../../App.module.css';

const SchoolPage = () => {
    return (
        <>
            <div className={ classNames(Styles.school, AppStyles.container) }>
                <h2>
                As nossas escolas Coimbra
                </h2>
                <h2 className={ Styles.subtitle }>
                Olivais e Santa Clara
                </h2>

                <p>
                Desde 1997 que a IH Coimbra Olivais / Santa Clara, em Coimbra, tem a reputação de ter um ensino de qualidade na área das línguas, desde a mais tenra idade até à idade adulta, assim como no ensino de Inglês técnico para Empresas.
                </p>
                <p>
                Em 2017 crescemos até à outra margem e abrimos uma nova escola nos Olivais. Na mesma altura, tornámo-nos Centro Autorizado de Exames de Cambridge Assessment English.
                </p>

                <h3>
                A nossa reputação
                </h3>
                <p>
                A nossa reputação de qualidade baseia-se nos ótimos resultados dos nossos alunos nos exames de Cambridge English: First, Advanced e Proficiency, bem como na metodologia moderna que usamos para o ajudar na aprendizagem eficaz de uma nova língua.
                </p>

                <h3>
                A nossa prioridade
                </h3>
                <p>
                Na IH Coimbra Olivais / Santa Clara, estamos sempre centrados em si, compreendendo as suas necessidades e motivações para que o possamos ajudar a dar ‘aquele salto extra’. As nossas turmas são pequenas, convidativas, com um ambiente favorável à aprendizagem e são lecionadas por profissionais certificados e qualificados que promovem uma atmosfera de aprendizagem descontraída e familiar.
                </p>
            
                <h3>
                A nossa filosofia
                </h3>
                <p>
                Na IH Coimbra Olivais / Santa Clara acreditamos que o propósito de qualquer língua é a comunicação, as nossas aulas são sempre lecionadas na língua alvo, encorajamo-lo e ajudamo-lo a utilizar a linguagem que já conhece. Acreditamos que a sua motivação para aprender vem da satisfação e dos desafios apresentados na sala de aula, pelo que integramos estes elementos; quer esteja a estudar gramática, a desenvolver a sua oralidade ou a suas capacidades de negociação.
                </p>
            
            
            
            </div>
            <div className={ Styles.contacts }>
                <Contacts />
            </div>
        </>
    );
};

export default SchoolPage;