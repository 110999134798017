import React, { useState } from 'react';
import { useMedia } from 'react-use-media';
import {
    Link,
    useLocation,
} from 'react-router-dom';
import classNames from 'classnames';
import { animateScroll } from 'react-scroll';

import Sticky from 'react-stickynode';

import Seo from './Seo';

import TopNav from './nav/TopNav';
import Breadcrumbs from '../breadcrumbs/Breadcrumbs';

import { Routes, Breakpoints } from '../../../constants';

import BrandImage from '../../../images/g10.png';
import MenuOpen from '../../../images/menu-open.svg';

import Styles from './Header.module.css';
import AppStyles from '../../../App.module.css';
import MobileTopNav from './nav/MobileTopNav';

const Header = () => {
    const { pathname } = useLocation();
    const [showMenu, setShowMenu] = useState(false);

    const mobile = useMedia({
        maxWidth: Breakpoints.MEDIUM,
    });

    const onHomeClick = () => {
        if (pathname === Routes.HOME) {
            animateScroll.scrollToTop();
        }
    };

    return (
        <>
            <Seo pathname={ pathname } />
            <Sticky>
                <div className={ Styles.headerWrapper }>
                    <div className={ classNames(Styles.header, AppStyles.container, 'sticky') }>
                        <div className={ Styles.logo }>
                            <Link to={Routes.HOME} onClick={ onHomeClick }>
                                <img src={ BrandImage } alt="IHC Logotipo"/>
                            </Link>
                        </div>
                        
                        {
                            !mobile &&
                            <TopNav />
                        }

                        {
                            mobile &&
                            <div className={ Styles.navigation }>
                                <a
                                    onClick={ () => {setShowMenu(true); console.log('show menu');} }>
                                    <img src={ MenuOpen } alt=""/>
                                </a>
                            </div>
                        }

                    </div>
                </div>
            </Sticky>
            
            {
                showMenu &&
                <MobileTopNav
                    onClose={ () => { setShowMenu(false); console.log('hide menu'); }} />
            }

            <Breadcrumbs pathname={ pathname } />
        </>
    );
};

export default Header;