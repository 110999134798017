import React, { useEffect } from 'react';

import Banner from './sections/Banner';
import Languages from './sections/Languages';
import Courses from './sections/Courses';
import News from './sections/News';
import Contacts from '../../shared/components/contacts/Contacts';

import Styles from './HomePage.module.css';
// import AppStyles from '../../App.module.css';

const HomePage = () => {
    // useEffect(() => {
    //     const hash = window.location.hash;
    //     console.log('hash', hash);
    // });

    return (
        <div>

            <Banner />

            <Languages />

            <Courses />

            <News />

            <div className={ Styles.contacts }>
                <Contacts />
            </div>

            
            {/* <div id="contacts">
                <h4>
                    Contacts
                </h4>
                <p>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ducimus, rem at? Libero eligendi aliquid placeat cumque amet officiis natus eaque aut. Possimus, optio! Quidem iste culpa quas et velit dolore.
                    Sint deserunt autem provident voluptatum, corrupti, voluptas libero laboriosam fuga id necessitatibus voluptates dolor ullam cumque impedit minus quidem fugiat. Nam sapiente cum excepturi tenetur odio, vero pariatur quibusdam molestias.
                    Ullam dolorem eius odit necessitatibus debitis voluptatem laudantium, labore esse deleniti numquam eveniet, iure molestias consequuntur praesentium consequatur inventore distinctio maxime? Aliquam perferendis a maxime alias? Placeat quo iusto assumenda!
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Neque nihil aut dicta aperiam quasi? Mollitia illum ab necessitatibus odio eveniet molestiae quaerat aperiam numquam tempore eos, maxime facilis sint beatae.
                    Eaque sint dicta assumenda reprehenderit dignissimos possimus quis, molestiae laboriosam, molestias distinctio repudiandae esse commodi ullam. Maxime laborum, vel magni sit, modi incidunt, facere quia aperiam esse quaerat ut magnam!
                    Harum ea, veniam deserunt dolorum est, doloribus sapiente possimus, ut magnam cum eos. Obcaecati nulla labore, beatae saepe libero laudantium doloribus consectetur nihil assumenda eveniet quas rerum possimus ullam iste.
                    Vitae, harum odio? Molestias maiores excepturi nemo placeat fugiat ut officiis voluptatem recusandae quae asperiores. Dolor voluptate nesciunt aspernatur, placeat earum optio nisi in atque et numquam obcaecati maxime culpa!
                    Vitae tenetur dolorum, explicabo provident, fugiat mollitia harum ducimus natus magnam architecto, itaque beatae id laboriosam velit quidem perspiciatis totam ab. Unde similique ullam earum amet inventore accusantium sunt libero?
                    Dolorum labore ut porro obcaecati quisquam nulla veniam? Blanditiis quae nisi repellendus nulla ullam molestiae adipisci amet corrupti possimus commodi. Odit repellat dolore, quo ab quas inventore. Doloribus, eligendi quibusdam.
                    Id libero excepturi animi mollitia ullam commodi harum ratione veniam aspernatur doloremque nam, quia adipisci alias repudiandae vitae, facilis esse illum ab earum? Neque velit praesentium laborum recusandae non ipsum!
                    Exercitationem facere veritatis quas laboriosam eveniet? Veritatis enim dolorem voluptate fugit officia incidunt, corrupti, voluptatem ipsam voluptatibus molestiae harum temporibus minus impedit nostrum quibusdam minima accusantium delectus, quas fuga saepe?
                    Dolorum doloremque aspernatur incidunt tempore voluptatibus dolorem, sunt esse maiores consectetur laborum ullam explicabo, error minus! Nulla, quis consequuntur expedita nostrum amet veniam atque magni tempora officia voluptatem id explicabo!
                    Eligendi possimus similique commodi dolores quia, sint soluta ipsum impedit ut eius, cumque ratione enim dolor quo necessitatibus tenetur ab ad, adipisci a aliquid perferendis. Voluptates nobis eum animi blanditiis.
                </p>
            </div> */}
        </div>
    );
};

export default HomePage;