import urlJoin from 'url-join';
import { Routes } from '../../constants';

export const adaptPosts = (posts) => {
    return posts.map((item)=>({
        id: item.id,
        title: item.title.rendered,
        date: item.date,
        image: item.featured_media,
        content: item.content.rendered,
        excerpt: item.excerpt.rendered,
        url: urlJoin(Routes.NEWS, item.slug)
    }));
};

export const adaptPages = (pages) => {
    return pages.map((item)=>({
        id: item.id,
        seoTitle: item.acf.seo_title,
        seoDescription: item.acf.seo_description,
        slug: item.acf.slug
    }));
};

export const adaptMedia = (media) => {
    return media.map((item)=>({
        id: item.id,
        url:
            (item.media_details &&
            item.media_details.sizes &&
            item.media_details.sizes.medium_large &&
            item.media_details.sizes.medium_large.source_url) ||
            null
    }));
};
