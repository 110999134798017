import * as postsActions from '../posts/actions';
import * as pagesActions from '../pages/actions';
import * as mediaActions from '../media/actions';

const namespace = 'page';

export const SET_CONFIG = `${namespace}/SET_CONFIG`;
export const SET_COOKIES = `${namespace}/SET_COOKIES`;
export const APP_INIT_START = `${namespace}/APP_INIT_START`;
export const APP_INIT_SUCCESS = `${namespace}/APP_INIT_SUCCESS`;
export const APP_INIT_FAIL = `${namespace}/APP_INIT_FAIL`;

export const init = () => (dispatch) => {
    dispatch({
        type: APP_INIT_START,
    });

    dispatch(postsActions.get());
    dispatch(pagesActions.get());
    dispatch(mediaActions.get());
};

export const setConfig = (config) => (dispatch) => {
    dispatch({
        type: SET_CONFIG,
        payload: config
    });
};

export const setCookies = (cookies) => (dispatch) => {
    dispatch({
        type: SET_COOKIES,
        payload: cookies
    });
};