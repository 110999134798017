/*global process*/
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

// console.log('App running...');
// console.log(process.env);

if (process.env.NODE_ENV === 'production' && window.location.protocol !== 'https:') {
    window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`);
}

ReactDOM.render(<App />, document.getElementById('root'));

