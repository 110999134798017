import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMedia } from 'react-use-media';

import IconMinus from '../../../images/icon-minus.svg';
import IconPlus from '../../../images/icon-plus.svg';
import IconMinusMobile from '../../../images/icon-minus-mobile.svg';
import IconPlusMobile from '../../../images/icon-plus-mobile.svg';

import { Breakpoints } from '../../../constants';

import Styles from './Accordion.module.css';

const Accordion = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const isMedium = useMedia({
        minWidth: Breakpoints.MEDIUM,
    });

    const handleClick = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={ Styles.accordion }>
            <div className={ Styles.header } onClick={ handleClick }>
                <div className={ Styles.title }>
                    <span dangerouslySetInnerHTML={{ __html: title }} />
                </div>
                <div className={ Styles.actions }>
                    {
                        isOpen ?
                            <div className={ Styles.image }>
                                {
                                    isMedium &&
                                    <img src={ IconMinus } alt="Close"/>
                                }
                                {
                                    !isMedium &&
                                    <img src={ IconMinusMobile } alt="Close"/>
                                }
                            </div> :
                            <div className={ Styles.image }>
                                {
                                    isMedium &&
                                    <img src={ IconPlus } alt="Open"/>
                                }
                                {
                                    !isMedium &&
                                    <img src={ IconPlusMobile } alt="Open"/>
                                }
                            </div>
                    }
                </div>
            </div>
            {
                isOpen &&
                <div className={ Styles.content }>
                    { children }
                </div>
            }
        </div>
    );
};

Accordion.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.object.isRequired,
};

export default Accordion;
