import React from 'react';
import classNames from 'classnames';
import { Element } from 'react-scroll';

import LanguagesBlocks from '../home/sections/languages/LanguagesBlocks';
import BlockLine from '../../shared/components/block-line/BlockLine';
import CalloutLink from '../../shared/components/callout-contacts/CalloutLink';

import Styles from './CoursesPage.module.css';
import AppStyles from '../../App.module.css';

const CoursesPage = () => {
    return (
        <div className={ classNames(Styles.courses, AppStyles.container) }>
            <h2 className={ Styles.title }>
                Os nossos cursos
            </h2>
            <p>
                As nossas escolas oferecem uma diversidade de cursos para todas as idades, permitindo a qualquer pessoa, de qualquer nível, aprender ou melhorar uma língua.
            </p>
            
            <h3>
                Aprenda uma língua ou melhore o seu nível
            </h3>

            <LanguagesBlocks />

            <Element name="courses-for-all" />
            <h3 className={ Styles.marginTop }>
                Cursos
            </h3>

            <BlockLine title="Crianças  (6 aos 11 anos)" description="A aprendizagem de uma língua nova aumenta a auto-confiança das crianças e e melhora o seu desempenho em todas as disciplinas da escola. A avaliação regular para mostrar a melhoria continua e uma forte incidência na comunicação oral com inglês para praticar no dia a dia mantém as crianças entusiasmadas com o ensino do inglês. Para motivar as crianças através de uma experiência de aprendizagem divertida." />
            
            <BlockLine title="Jovens  (12 aos 16 anos)" description="O ensino com materiais que conseguem aumentar a visão global do mundo dos alunos, prepara-os para irem mais longe na sua educação futura, tal como lhes abre mais portas para carreiras bem sucedidas." />
            
            <BlockLine title="Cursos para Adultos" description="Um ensino que lhe vai permitir ter experiências de viagens mais agradáveis, ter um acesso mais alargado aos media e que ajuda a função neurológica. Está provado cientificamente que aprender uma língua nova reduz o risco de ter Alzheimer. O inglês específico para o trabalho deve melhorar o atendimento ao cliente e a obtenção de novos clientes." />

            {/* <Element name="courses-special" />
            <h3 className={ Styles.marginTop }>
                Cursos especializados
            </h3> */}

            <BlockLine title="Preparação para exames" description="Com uma abordagem sistemática, baseada na formação e treino específicos para o sucesso nos resultados dos exames, quando ao mesmo tempo, se estão a melhorar capacidades gerais de comunicação a todos os níveis, os nossos cursos transformam-no num comunicador eficiente e confiante com o mundo aos seus pés. Temos cursos de preparação para os exames Cambridge English Qualifications." />

            <BlockLine title="Português para Estrangeiros" description="Estes cursos são direcionados a estrangeiros que pretendem aprender ou melhorar o seu conhecimento da língua portuguesa. Indicado para quem quer obter uma qualificação na língua portuguesa (por exemplo, CIPLE, DEPLE, DIPLE)." />
            
            <BlockLine title="Survival Portuguese" description="Survival Portuguese Cursos intensivos, de curta duração, orientados para uma comunicação básica específica do dia-a-dia, para quem acabou de conhecer a língua portuguesa ou para quem se prepara para viver para Portugal. Vai se sentir cada vez mais integrado à medida que avança no curso." />

            <BlockLine title="Cursos Específicos" description="Inglês específico para a sua área de negócio, seja hotelaria e restauração, saúde, contabilidade e finanças, imobiliária ou outros. Estes cursos permitirão a melhoria da comunicação e negociação de indivíduos e empresas." />
            
            <BlockLine title="Workshops" description="Quer conhecer um pouco mais de negociação, emailing, apoio ao cliente?</br>Venha aprender connosco a melhor maneira de comunicar estes temas em inglês." />

            

            <CalloutLink />

        </div>
    );
};

export default CoursesPage;