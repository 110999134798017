import React from 'react';
import classNames from 'classnames';

import Levels from './levels/Levels';
import Callout from '../../shared/components/callout-contacts/Callout';

import CambridgeAuthorizedImage from '../../images/cambridge_authorized.svg';
import CambridgeImage from '../../images/cambridge-courses.svg';

import Styles from './ExamsPage.module.css';
import AppStyles from '../../App.module.css';

const ExamsPage = () => {
    return (
        <div className={ classNames(Styles.exams, AppStyles.container) }>
            <h2>
                Exames de Cambridge Assessment English
            </h2>
            <h2 className={ Styles.subtitle }>
                Pre-A1 a C2 Proficiency
            </h2>
            <p>
                Somos Centro Autorizado de Exames Cambridge Assessment English com sessões de exames para os vários níveis ao longo de todo o ano.
            </p>
            <p>
                Para dar a conhecer o formato dos exames Cambridge English e desenvolver/praticar as capacidades linguísticas de modo a ter sucesso nos exames.
            </p>
            
            <Levels />

            <div className={ Styles.logos }>
                <div className={ Styles.logosBox }>
                    <img src={ CambridgeAuthorizedImage } alt=""/>
                    <img src={ CambridgeImage } alt=""/>
                </div>
            </div>

            <Callout>
                <h1 className={ Styles.light }>
                    Pretende fazer um exame 
                </h1>
                <h1>
                    Cambridge English Qualifications?
                </h1>
            </Callout>
        </div>
    );
};

export default ExamsPage;