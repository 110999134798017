export const Routes = {
    HOME: '/',
    CONTACTS: '/#contactos',
    COURSES: '/cursos',
    EXAMS: '/exames',
    SCHOOL: '/a-escola',
    FAQ: '/faq',
    TERMS: '/cookies',
    PRIVACY: '/privacidade',
    NEWS: '/noticias',
    NEWS_SINGLE: '/noticias/:slug',
};

export const Pages = {
    HOME: 'Início',
    COURSES: 'Cursos',
    EXAMS: 'Exames',
    SCHOOL: 'A Escola',
    FAQ: 'Questões frequentes',
    TERMS: 'Política de Cookies',
    PRIVACY: 'Política de Privacidade',
    NEWS: 'Notícias',
    NEWS_SINGLE: 'Notícias > {{title}}',
};

export const Breakpoints = {
    XLARGE: 1200,
    LARGE: 992,
    MEDIUM: 768,
};
