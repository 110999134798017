import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import classNames from 'classnames';
import Cookies from 'js-cookie';

import * as pageActions from '../../state/page/actions';

import Styles from './CookiesConsent.module.css';
import AppStyles from '../../../App.module.css';

const CookiesConsent = () => {
    const dispatch = useDispatch();
    const cookiesConsent = useSelector(state => state.page.cookies);

    const handleClick = () => {
        Cookies.set('cookies_consented', 'true');
        dispatch(pageActions.setCookies(true));
    };

    if (cookiesConsent) {
        return null;
    }

    return (
        <div className={ Styles.cookies }>
            <div className={ AppStyles.container }>
                <div className={ Styles.box }>
                    <div className={ Styles.text }>
                        Este website usa cookies para melhorar a sua experiência como utilizador.  Ao continuar a navegar neste site, concorda com a sua utilização.
                    </div>
                    <div className={ Styles.action }>
                        <a
                            onClick={handleClick}>
                            OK!
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CookiesConsent;