import React from 'react';
import {
    Link,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
// import classNames from 'classnames';

import Styles from './ListItem.module.css';

const ListItem = ({ image, date, title, excerpt, url }) => {
    const media = useSelector(state => state.media.data);
    const mediaItem = media.find((item) => item.id === image);
    
    return (
        <div className={ Styles.item}>
            <div className={ Styles.image }>
                {
                    mediaItem &&
                    <img src={ mediaItem.url } alt=""/>
                }
            </div>
            <div className={ Styles.box }>
                <div className={ Styles.date }>
                    { moment(date).format('D MMMM - YYYY') }
                </div>
                <div className={ Styles.title }>
                    {title}
                </div>
                <div className={ Styles.excerpt }>
                    <div dangerouslySetInnerHTML={{ __html: excerpt }} />
                </div>
                <div className={ Styles.link }>
                    <Link to={url}>
                        Ler mais
                    </Link>
                </div>
            </div>
            
        </div>
    );
};

export default ListItem;