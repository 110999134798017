import React from 'react';

import Styles from './LanguagesBlocks.module.css';

const LanguagesBlocks = () => {
    return (
        <div className={ Styles.blocks }>
            <div className={ Styles.blockItem }>
                <span>
                        Inglês
                </span>
            </div>
            <div className={ Styles.blockItem }>
                <span>
                        Francês
                </span>
            </div>
            <div className={ Styles.blockItem }>
                <span>
                        Alemão
                </span>
            </div>
            <div className={ Styles.blockItem }>
                <span>
                        Espanhol
                </span>
            </div>
            <div className={ Styles.blockItem }>
                <span>
                        Italiano
                </span>
            </div>
            <div className={ Styles.blockItem }>
                <span>
                    Português para Estrangeiros
                </span>
            </div>
        </div>
    );
};

export default LanguagesBlocks;