import React from 'react';
import classNames from 'classnames';

import LanguagesBlocks from './languages/LanguagesBlocks';

import Styles from './Languages.module.css';
import AppStyles from '../../../App.module.css';

const Languages = () => {
    return (
        <div className={ classNames(Styles.languages, AppStyles.container) }>
            <h2>
                Aprenda uma língua ou melhore o seu nível
            </h2>

            <LanguagesBlocks />
            
            <p className={ Styles.disclaimer }>
                * Ensino de algumas línguas poderá estar condicionado ao número de inscritos.  
            </p>
        </div>
    );
};

export default Languages;
