import React from 'react';

import Styles from './LevelItem.module.css';

const LevelItem = ({ grade, description }) => {
    return (
        <div className={ Styles.levelItem }>
            <div className={ Styles.grade }>
                { grade }
            </div>
            <div className={ Styles.description }>
                { description }
            </div>
        </div>
    );
};

export default LevelItem;
