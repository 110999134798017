import { combineReducers } from 'redux';

import { SET_CONFIG, SET_COOKIES } from './actions';

export const initialState = {
    config: {},
    cookies: false,
};

export const config = (state = initialState.config, action) => {
    switch (action.type) {
    case SET_CONFIG:
        // console.log('SET_CONFIG Action', action.payload);
        return action.payload;
    default:
        return state;
    }
};

export const cookies = (state = initialState.cookies, action) => {
    switch (action.type) {
    case SET_COOKIES:
        // console.log('SET_COOKIES Action', action.payload);
        return action.payload;
    default:
        return state;
    }
};

export default combineReducers({
    config,
    cookies,
});