/*global process*/
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
// import moment from 'moment';
import 'moment/locale/pt.js';
import Cookies from 'js-cookie';

import configureStore from './shared/store/configureStore';
import defaultConfig from './config/default';
import devConfig from './config/default.dev';

import Routing from './Routing';
import ScrollToTop from './shared/components/layout/ScrollToTop';
import CookiesConsent from './shared/components/cookies-consent/CookiesConsent';
import GoTop from './shared/components/go-top/GoTop';
import Layout from './shared/components/layout/Layout';

import * as pageActions from './shared/state/page/actions';

const store = configureStore();

// import logo from './logo.svg';
// import './App.css';

// const baseUrl = window.location.origin;
// console.log('baseUrl', baseUrl);

// console.log('defaultconfig: ', defaultConfig);
// console.log('devconfig: ', devConfig);

let appConfig = { ...defaultConfig };
if (process.env.NODE_ENV === 'development') {
    // console.log('dev mode');
    appConfig = { ...devConfig };
}

store.dispatch(pageActions.setConfig(appConfig));

store.dispatch(pageActions.init());

// moment.locale('pt'); // 'en'

const cookie = Cookies.get('auth');
if (cookie) {
    const auth = JSON.parse(cookie);
    console.log('cookie: ', auth);
    
    // if (auth) {
    //     store.dispatch(authActions.getTokenSuccess(auth));
    // }
}
    
const cookiesConsented = Cookies.get('cookies_consented');
if (cookiesConsented) {
    const cookiesConsentedValue = JSON.parse(cookiesConsented);
    store.dispatch(pageActions.setCookies(cookiesConsentedValue));
}

function App() {
    return (
        <Provider store={store}>
            <Router>
                <ScrollToTop />
                <GoTop />
                <CookiesConsent />
                <Layout>
                    <Routing />
                </Layout>
            </Router>
        </Provider>
    );
}

export default App;
