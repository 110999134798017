import React from 'react';
import classNames from 'classnames';
import { scroller } from 'react-scroll';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import { Routes } from '../../../constants';

import ArrowRight from '../../../images/arrow-right.svg';
import ArrowRightSmaller from '../../../images/arrow-right-smaller.svg';

import Styles from './Courses.module.css';
import AppStyles from '../../../App.module.css';

const Courses = () => {
    let history = useHistory();

    function goToCourses(elementName) {
        history.push(Routes.COURSES);
        setTimeout(() => {
            scroller.scrollTo(elementName, {
                // duration: 1500,
                // delay: 100,
                smooth: true,
                offset: -70, // Scrolls to element + 50 pixels down the page
            }); 
        }, 100);
    }

    return (
        <div className={ classNames(Styles.courses, AppStyles.container) }>
            <div className={ Styles.courseItem }>
                <h2>
                    Cursos para todos
                </h2>
                <div
                    onClick={() => goToCourses('courses-for-all')}
                    className={ Styles.coursesForAll }>
                    <div className={ classNames(Styles.blockItem, Styles.course1) }>
                        <span>
                            <strong>Crianças</strong> <br/>
                            (6 aos 11 anos)
                        </span>
                    </div>
                    <div className={ classNames(Styles.blockItem, Styles.course2) }>
                        <span>
                            <strong>Jovens</strong> <br/>
                            (12 aos 16 anos)
                        </span>
                    </div>
                    <div className={ classNames(Styles.blockItem, Styles.course3) }>
                        <span>
                            <strong>Adultos</strong> e <strong>Séniores</strong>
                        </span>
                    </div>
                    <div className={ classNames(Styles.blockItem, Styles.course4) }>
                        <span>
                            Preparação para <strong>exames</strong>
                        </span>
                    </div>
                </div>
            </div>
            <div className={ Styles.courseItem }>
                <h2>
                    Cursos especializados
                </h2>
                <div
                    onClick={() => goToCourses('courses-special')}
                    className={ Styles.specialCourses }>
                    <div className={ classNames(Styles.blockItem, Styles.course5) }>
                        <span>
                            Língua técnica para <br/>
                            cursos académicos
                        </span>
                    </div>
                    <div className={ classNames(Styles.blockItem, Styles.course6) }>
                        <span>
                            Melhoria de comunicação <br/>
                            e negociação para empresas
                        </span>
                    </div>
                </div>
            </div>
            <div className={ Styles.callout }>
                <div>
                    <span>
                        Consulte toda a informação sobre os
                        cursos e línguas das nossas escolas
                    </span>
                </div>
                <div className={ Styles.calloutLink }>
                    <Link
                        to={Routes.COURSES}>
                            Saber mais
                        <img src={ ArrowRight } alt="Read more"/>
                    </Link>
                </div>
            </div>
            <div className={ Styles.examsCallout }>
                <div className={ Styles.calloutText }>
                    <h1>
                        Exames Cambridge English
                    </h1>
                    <span>
                        Somos Centro Autorizado de Exames Cambridge Assessment English com sessões de exames para os vários níveis ao longo de todo o ano.
                    </span>
                </div>
                <div className={ Styles.calloutLink }>
                    <Link
                        to={Routes.EXAMS}>
                            Saber mais
                        <img src={ ArrowRightSmaller } alt="Read more"/>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Courses;
