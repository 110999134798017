import React from 'react';
import { scroller } from 'react-scroll';
import {
    NavLink,
    useLocation,
    useHistory,
} from 'react-router-dom';
// import classNames from 'classnames';

import { Routes } from '../../../../constants';

import BrandImage from '../../../../images/g10.png';
import MenuOpen from '../../../../images/menu-close.svg';

import Styles from './MobileTopNav.module.css';

const MobileTopNav = ({ onClose }) => {
    const { pathname } = useLocation();
    let history = useHistory();

    function goToHomeContacts() {
        history.push(Routes.HOME);
        setTimeout(() => {
            onClose();
            scroller.scrollTo('contacts', {
                // duration: 1500,
                // delay: 100,
                smooth: true,
                // offset: 50, // Scrolls to element + 50 pixels down the page
            }); 
        }, 100);
    }

    return (
        <div className={ Styles.navigation }>
            <div className={ Styles.header }>
                <img src={ BrandImage } alt="IHC Logotipo"/>
                <div className={ Styles.action }>
                    <a
                        onClick={ onClose }>
                        <img src={ MenuOpen } alt=""/>
                    </a>
                </div>
            </div>
            <ul className={ Styles.list }>
                <li className={ Styles.listItem }>
                    <NavLink
                        onClick={ onClose }
                        exact
                        activeClassName={ Styles.active }
                        to={Routes.HOME}>
                        Início
                    </NavLink>
                </li>
                <li className={ Styles.listItem }>
                    <NavLink
                        onClick={ onClose }
                        activeClassName={ Styles.active }
                        to={Routes.COURSES}>
                        Cursos
                    </NavLink>
                </li>
                <li className={ Styles.listItem }>
                    <NavLink
                        onClick={ onClose }
                        activeClassName={ Styles.active }
                        to={Routes.EXAMS}>
                        Exames
                    </NavLink>
                </li>
                {/* <li className={ Styles.listItem }>
                    <NavLink
                        onClick={ onClose }
                        activeClassName={ Styles.active }
                        to={Routes.NEWS}>
                                Notícias
                    </NavLink>
                </li> */}
                <li className={ Styles.listItem }>
                    <NavLink
                        onClick={ onClose }
                        activeClassName={ Styles.active }
                        to={Routes.SCHOOL}>
                        A Escola
                    </NavLink>
                </li>
                <li className={ Styles.listItem }>
                    <a
                        role="button"
                        tabIndex="0"
                        onClick={ goToHomeContacts }>
                        Contacto
                    </a>
                </li>
            </ul>
        </div>
    );
};

export default MobileTopNav;