import React from 'react';
import { useSelector } from 'react-redux';
import {
    // useParams,
    useLocation
} from 'react-router-dom';
import moment from 'moment';
import classNames from 'classnames';

import Styles from './SinglePage.module.css';
import AppStyles from '../../App.module.css';

const SinglePage = () => {
    const { pathname } = useLocation();
    const news = useSelector(state => state.posts.data);
    const media = useSelector(state => state.media.data);
    const post = news.find(item => item.url === pathname);
    
    if (!post) {
        return null;
    }

    const mediaItem = media.find((item) => item.id === post.image);

    return (
        <div className={ classNames(Styles.item, AppStyles.container) }>
            <div className={ Styles.date }>
                { moment(post.date).format('D MMMM - YYYY') }
            </div>
            <h2>
                { post.title }
            </h2>
            <div className={ Styles.content }>
                {
                    mediaItem &&
                    <div className={ Styles.image }>
                        <img src={ mediaItem.url } alt=""/>
                    </div>
                }

                <div dangerouslySetInnerHTML={{ __html: post.content }} />
            </div>
        </div>
    );
};

export default SinglePage;