import React from 'react';
import { useMedia } from 'react-use-media';
import { scroller } from 'react-scroll';
import {
    Link,
    useHistory,
} from 'react-router-dom';
import classNames from 'classnames';

import { Routes, Breakpoints } from '../../../constants';

import LineImage from '../../../images/rainbow-line.png';
import LineImageSmall from '../../../images/rainbow-line.png';
import SponsorMinisterioImage from '../../../images/sponsor-ministerio.jpg';
import SponsorCambridgeImage from '../../../images/sponsor-cambridge.svg';
import SponsorIHImage from '../../../images/sponsor-ih.jpg';
import IconArrowSmaller from '../../../images/arrow-right-smaller.svg';

import Styles from './Footer.module.css';
import AppStyles from '../../../App.module.css';

const Footer = () => {
    let history = useHistory();

    function goToHomeContacts() {
        history.push(Routes.HOME);
        setTimeout(() => {
            scroller.scrollTo('contacts', {
                // duration: 1500,
                // delay: 100,
                smooth: true,
                // offset: 50, // Scrolls to element + 50 pixels down the page
            }); 
        }, 100);
    }

    const isMedium = useMedia({
        minWidth: Breakpoints.MEDIUM,
    });

    return (
        <div className={ Styles.footer }>
            <div className={ Styles.line }>
                {
                    isMedium &&
                    <img src={ LineImage } alt=""/>
                }
                {
                    !isMedium &&
                    <img src={ LineImageSmall } alt=""/>
                }
            </div>
            <div className={ classNames(Styles.box, AppStyles.container) }>
                <div className={ Styles.text }>
                    International House<br />
                    Olivais - Santa Clara<br />
                    Coimbra
                </div>
                <div className={ Styles.sponsors }>
                    <div>
                        Certificações e Apoios
                    </div>
                    <div className={ Styles.logos }>
                        <img src={ SponsorMinisterioImage } alt=""/>
                        <img src={ SponsorCambridgeImage } alt=""/>
                        <img src={ SponsorIHImage } alt=""/>
                    </div>
                </div>
                <div className={ Styles.faq }>
                    <span>
                        Tem dúvidas?
                    </span>
                    <Link
                        to={Routes.FAQ}>
                        Consulte o FAQ
                        <img src={ IconArrowSmaller } alt=""/>
                    </Link>
                    {/* <Link to={Routes.CONTACTS}>
                        Contacte-nos >>
                    </Link> */}
                    <a
                        role="button"
                        tabIndex="0"
                        onClick={ goToHomeContacts }>
                        Contacte-nos
                        <img src={ IconArrowSmaller } alt=""/>
                    </a>
                </div>
                <div className={ Styles.copyright }>
                    IHOSC &copy; Copyright - Todos os direitos reservados
                </div>
                <div className={ Styles.terms }>
                    <Link
                        to={Routes.TERMS}>
                        Cookies
                    </Link>
                    <Link
                        to={Routes.PRIVACY}>
                        Política de privacidade
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Footer;