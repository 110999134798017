import React from 'react';
import classNames from 'classnames';

import Styles from './PrivacyPage.module.css';
import AppStyles from '../../App.module.css';

const PrivacyPage = () => {
    return (
        <div className={ classNames(Styles.privacy, AppStyles.container) }>
            <h2 className={ Styles.title }>
                Política de Privacidade e Proteção de Dados Pessoais
            </h2>

            <p>
                A International House Coimbra – Olivais | Santa Clara oferece vários produtos e/ ou serviços relacionados com a educação.
            </p>
            <p>
                A informação contida no presente documento pretende informar as regras gerais relativas ao tratamento de dados pessoais, em cumprimento do disposto na legislação de protecção de dados pessoais em vigor, o Regulamento Geral sobre a Proteção de Dados (RGPD9, Regulamento (UE) 2016/679 do Parlamento Europeu e do Conselho de 27 de abril de 2016, nomeadamente, como a International House Coimbra – Olivais | Santa Clara usa e armazena os dados pessoais recolhidos.
            </p>
            <p>
                É importante para nós que protejamos a sua privacidade e os seus dados pessoais.
            </p>
            <p>
                Se tiver alguma dúvida sobre este assunto, não hesite em contactar-nos via correio electrónico, para o endereço epd.ihcoimbra.osc@gmail.com.
            </p>
            <p>
                É importante que leia este documento, bem como qualquer outro que possamos fornecer aquando da recolha dos seus dados pessoais, incluindo se se tratar de um aluno, candidato, parceiros, clientes ou fornecedores, que deverão também tomar conhecimento do conteúdo do documento Política de Privacidade do Candidato.
            </p>

            <h4 className={ Styles.subTitle }>
                Dados pessoais utilizados
            </h4>

            <p>
                Os dados pessoais que utilizamos variam de acordo com o produto e/ou serviço que estamos a oferecer, na medida do estritamente necessário.
            </p>
            <p>
                Nesse sentido, são recolhidos vários tipos de dados pessoais, designadamente:
            </p>
            <p>
                <ul>
                    <li>
                        Dados de identificação (nome, número de documento de identificação, data de nascimento, número de identificação fiscal);
                    </li>
                    <li>
                        Dados de identificação do encarregado de educação ou representante legal, quando se trata de menores;
                    </li>
                    <li>
                        Dados de contacto (morada, endereço de correio electrónico, telefone, telemóvel);
                    </li>
                </ul>      
                A menos que seja uma obrigação legal, nunca tratamos dados pessoais relacionados com a sua origem racial ou étnica, opiniões políticas, convicções religiosas ou filosóficas, filiação sindical, dados genéticos ou dados relativos à sua vida ou orientação sexual.
            </p>
            <p>
                Em determinadas situações, podemos precisar de lhe solicitar dados sensíveis, como por exemplo, informações sobre a sua saúde (necessidades especiais educativas). No entanto, este tipo de informação sensível só é recolhida se for identificada por si, e sempre com o seu consentimento, ou, caso se trate de menor, do consentimento dos pais ou o seu representante legal.
            </p>
            <p>
                Os dados pessoais que utilizamos podem ser directamente fornecidos por si ou obtidos nas seguintes formas:
            </p>
            <p>
                <ul>
                    <li>
                        Clientes corporativos;
                    </li>
                    <li>
                        Parceiros comerciais a quem tenha sido consentida a transmissão;
                    </li>
                    <li>
                        Inscrição no nosso website;
                    </li>
                    <li>
                        No decorrer de contacto connosco.
                    </li>
                </ul>
            </p>


            <h4 className={ Styles.subTitle }>
                Fundamento para a recolha de dados pessoais
            </h4>

            <p>
                Os dados pessoais recolhidos são utilizados para as seguintes finalidades:
            </p>
            <p>
                1. Para cumprir interesses legítimos da IH Coimbra – Olivais | Santa Clara:
                <ul>
                    <li>
                        - Fornecer-lhe os produtos e/ou serviços e informações que solicitou;
                    </li>
                    <li>
                        - Desenvolvimento e melhoria dos nossos produtos e/ou serviços, usando para tal o endereço de correio electrónico por si indicado;
                    </li>
                    <li>
                        - Gestão de tecnologias de informação (TI), incluindo a gestão de infraestruturas (plataformas partilhadas) continuidade do negócio                        e segurança TI;
                    </li>
                    <li>
                        - Realização de inquéritos de satisfação, o que permitirá monitorizar e melhorar os nossos processos, produtos e/ou serviços;
                    </li>
                    <li>
                        - Realização de acções de marketing e acções promocionais;
                    </li>
                </ul>
            </p>
            <p>
                2. Para cumprir com as nossas obrigações legais e regulamentares;
            </p>
            <p>
                3. Para execução de um contrato consigo.
            </p>


            <h4 className={ Styles.subTitle }>
                Partilha dos seus dados pessoais
            </h4>

            <p>
                Os seus dados pessoais podem ser partilhados, com o seu consentimento, entre parceiros comerciais e de acordo com os regulamentos próprios em vigor, bem como nas situações previstas no RGPD.
            </p>


            <h4 className={ Styles.subTitle }>
            Transferência de dados pessoais
            </h4>

            <p>
            No caso de transferências internacionais originárias do Espaço Económico Europeu (EEE), para um país fora do EEE relativamente aos quais a Comissão Europeia tiver decidido que asseguram de forma adequada a proteção de dados, os seus dados pessoais podem ser transferidos com base neste fundamento.
            </p>
            <p>
Para obter informações adicionais sobre este ponto, poderá enviar um pedido por escrito endereçado para o contacto epd.ihcoimbra.osc@gmail.com.
            </p>


            <h4 className={ Styles.subTitle }>
            Período de conservação dos seus dados pessoais
            </h4>

            <p>
            Os seus dados pessoais serão conservados pelo período máximo exigido para cumprimento de obrigações legais e regulamentares aplicáveis, ou outro período de modo a ser possível por nos realizar análises dos nossos serviços.
            </p>


            <h4 className={ Styles.subTitle }>
            Os seus direitos
            </h4>

            <p>
            De acordo com as normas aplicáveis, o titular dos dados tem os seguintes direitos:
            </p>
            <p>
                <ul>
                    <li>
O direito a ser informado;
                    </li>
                    <li>
O direito de acesso aos seus dados;
                    </li>
                    <li>
O direito à rectificação dos seus dados;
                    </li>
                    <li>
O direito a ser esquecido/ apagado;
                    </li>
                    <li>
O direito à restrição de processamento;
                    </li>
                    <li>
O direito à portabilidade dos dados;
                    </li>
                    <li>
O direito à objecção;
                    </li>
                    <li>
Direitos relacionados com o processamento automático, nomeadamente a definição de perfis.
                    </li>
                    <li>
Pode exercer os seus direitos acima elencados: presencialmente nas nossas instalações, através de carta registada, através de contacto telefónico, através de correio electrónico.
                    </li>
                </ul>
            </p>
            <p>
Em qualquer um dos casos, o titular dos dados deverá proceder à respectiva identificação.
            </p>
            <p>
O titular dos dados terá ainda direito à apresentação de reclamação junto da Comissão Nacional de Proteção de Dados, ou outra autoridade de controlo a quem venha a ser atribuída esta competência, cujos contactos podem ser consultados em www.cnpd.pt.
            </p>


            <h4 className={ Styles.subTitle }>
            Alteração à Política de Privacidade e Proteção de Dados Pessoais
            </h4>

            <p>
            Podemos fazer alterações à presente Política de Privacidade e Proteção de Dados informando-o do mesmo, publicitando nas nossas instalações e website.
            </p>
            <p>
As alterações só produzirão efeitos no momento em que foram publicitadas.
            </p>

        </div>
    );
};

export default PrivacyPage;