import React from 'react';
import classNames from 'classnames';
import { scroller } from 'react-scroll';
import { useHistory } from 'react-router-dom';

import Accordion from '../../shared/components/accordion';
import Contacts from '../../shared/components/contacts/Contacts';

import { Routes } from '../../constants';

import Styles from './FaqPage.module.css';
import AppStyles from '../../App.module.css';

const FaqPage = () => {
    let history = useHistory();

    function goToHomeContacts() {
        history.push(Routes.HOME);
        setTimeout(() => {
            scroller.scrollTo('contacts', {
                // duration: 1500,
                // delay: 100,
                smooth: true,
                // offset: 50, // Scrolls to element + 50 pixels down the page
            }); 
        }, 100);
    }

    return (
        <>
            <div className={ classNames(Styles.faq, AppStyles.container) }>
                <h2>
                Questões frequentes
                </h2>
                <h2 className={ Styles.subtitle }>
                Se tem dúvidas consulte a nossa lista de perguntas e respostas rápidas para ficar esclarecido sem ter de nos contactar.
                </h2>

                <Accordion
                    title="O que preciso fazer para me inscrever num curso de línguas na IH Coimbra - OSC?"
                >
                    <>
                        <p>
                        Se tiver conhecimentos da língua é necessário fazer uma prova de aferição/ teste diagnóstico (teste escrito e oral) para avaliar o seu nível.
                        </p>
                        <h4>
                            <a onClick={ goToHomeContacts }>
                            Marque o seu teste agora!
                            </a>
                        </h4>
                    </>
                </Accordion>
            
                <Accordion
                    title="Qual é a duração dos cursos na IH Coimbra - OSC?"
                >
                    <>
                        <p>
                        O ano letivo na nossa escola decorre de Outubro a Junho. <br />
                        Existem também cursos intensivos durante os meses de Julho e setembro.
                        </p>
                    </>
                </Accordion>

                <Accordion
                    title="Quantas horas são por semana? Por ano?"
                >
                    <>
                        <p>
                        A IH Coimbra - OSC garante um mínimo de 90 períodos de 50 minutos por ano letivo (ou 60 aulas de 75 minutos), para todos os níveis de Junior, Teens e Adults. Os níveis de Kiddies têm, no mínimo, um total de 45 períodos de 50 minutos (ou 30 aulas de 75 minutos) e os Pre-Kiddies têm um mínimo de 30 aulas de 50 minutos por ano. Lembramos que a distribuição destes tempos letivos não é homogénea durante os nove meses de aulas.
                        </p>
                    </>
                </Accordion>

                <Accordion
                    title=" Quantos alunos pode ter uma turma?"
                >
                    <>
                        <p>
                        15 é o número máximo de alunos por turma para assegurar a nossa metodologia de proximidade, acompanhamento e comunicação. No entanto, sempre que possível, evitamos ter grupos com o número máximo.
                        </p>
                    </>
                </Accordion>
            
                <Accordion
                    title="Que tipo de metodologia pratica a IH Coimbra - OSC?"
                >
                    <>
                        <p>
                        Todos os nossos alunos têm avaliação contínua baseada no trabalho e participação na aula e nos  progressos conseguidos. Como complemento, os alunos nos níveis Junior, Teens, Adults e Exams realizam um teste formal por ano.
                        </p>
                    </>
                </Accordion>
            
                <Accordion
                    title="Os professores têm habilitações no ensino da língua?"
                >
                    <>
                        <p>
                        Sim! Todos os professores da IH Coimbra - OSC têm qualificações reconhecidas mundialmente (Cambridge CELTA e DELTA), International House Certificate in Teaching Young Learners and Teenagers, Certificate in Advanced Methodology, entre outros. <br />
                        Os professores também frequentam regularmente ações de formação.
                        </p>
                    </>
                </Accordion>
            
                <Accordion
                    title="Existem apenas cursos para crianças e jovens ou também têm cursos para adultos?"
                >
                    <>
                        <p>
                        Temos cursos para adultos com horários pós-laborais (das 19:15 às 20h30 ou das 20:15 às 21:20, conforme os dias) e à hora do almoço. <br />
                        O nosso objetivo é assegurar sempre uma solução para a sua necessidade.
                        </p>
                    </>
                </Accordion>
            
                <Accordion
                    title="Sou adulto mas com poucos conhecimentos da língua que quero aprender; serei colocado numa turma com crianças?"
                >
                    <>
                        <p>
                        Não! Temos turmas apenas para adultos. <br />
                        Os nossos alunos mais novos (Kids, Juniors eTeens) frequentam turmas próprias com uma metodologia adequada à sua faixa etária.
                        </p>
                    </>
                </Accordion>
            
                <Accordion
                    title="Se pensar que estou no nível errado, o que posso fazer?"
                >
                    <>
                        <p>
                        Apesar de ser uma situação rara, caso sinta que não está no nível mais adequado para si, pode falar com o seu professor (ou um representante da direção) que falará com a equipa pedagógica com o intuito de avaliar a sua situação. <br />
                        Tentamos sempre encontrar uma solução para a sua exposição, havendo inclusive a possibilidade de experimentar uma aula num grau diferente.
                        </p>
                    </>
                </Accordion>
            
                <Accordion
                    title="Sou Encarregado de Educação de um aluno que frequenta um curso na IH Coimbra e queria ter informações acerca dos progressos / aproveitamento. É possível?"
                >
                    <>
                        <p>
                        Sim, somos adeptos de uma educação conjunta com os educadores das nossas crianças pelo que existem reuniões de pais três vezes por ano (no caso dos Kids e Juniors/Teens, respetivamente). <br />
                        Existe também a possibilidade de agendar uma reunião com o professor, através do telefone, email ou pessoalmente. Por razões logísticas, não é possível aceder a pedidos de reunião sem marcação prévia.
                        </p>
                    </>
                </Accordion>
            
                <Accordion
                    title="Quais são as condições de pagamento em vigor?"
                >
                    <>
                        <p>
                        Para reservar um lugar na turma, o aluno deve pagar uma inscrição (renovável anualmente). O valor total do curso é dividido em 9 frações iguais independentemente das interrupções programadas. <br />
                        Pode optar pelo pagamento mensal ou trimestral das propinas, sendo que o pagamento deve ser efetuado entre o dia 1 e 8 de cada mês ou até ao dia 15 do primeiro mês do trimestre (por exemplo, Outubro, Janeiro e Abril).
                        </p>
                    </>
                </Accordion>
            
                <Accordion
                    title="Quais os meios de pagamento disponíveis?"
                >
                    <>
                        <p>
                        A IH Coimbra - OSC aceita os seguintes meios de pagamento: Multibanco, dinheiro, cheque e transferência bancária.
                        </p>
                    </>
                </Accordion>
            
                <Accordion
                    title="Se tiver que cancelar a minha matrícula, terei que pagar o curso todo?"
                >
                    <>
                        <p>
                        Não! No entanto, por questões de organização de turmas e de gestão de tesouraria, deverá avisar a Secretaria com um mês de antecedência. No entanto, não há lugar à devolução do valor da inscrição.
                        </p>
                    </>
                </Accordion>
            
                <Accordion
                    title="Somos 2 pessoas da mesma família a frequentar a escola. Temos direito a desconto?"
                >
                    <>
                        <p>
                        Sim, 2 pessoas da mesma família usufruem de 5% de desconto na mensalidade, 3 pessoas ou mais usufruem de 10% de desconto. <br />
                        Os alunos que frequentam cursos de duas línguas usufruem de 5% de desconto em cada língua.
                        </p>
                    </>
                </Accordion>
            
                <Accordion
                    title="Quero obter um certificado que reconheça os meus conhecimentos. É possível?"
                >
                    <>
                        <p>
                        Sim! A nossa escola é um Centro Autorizado de Exames de Cambridge English Language Assessment e preparamos os nossos alunos para os exames de Young Learners (Starters, Movers e Flyers), Preliminary, First, Advanced e Proficiency. <br />
                        As sessões de exames são abertas tanto para alunos internos como externos. 
                        </p>
                    </>
                </Accordion>
            
                <Accordion
                    title="Qual é a duração dos cursos na IH Coimbra - OSC?"
                >
                    <>
                        <p>
                        O ano letivo na nossa escola decorre de Outubro a Junho. <br />
                        Existem também cursos intensivos durante os meses de Julho e setembro.
                        </p>
                    </>
                </Accordion>
            
                <Accordion
                    title="Vejo que há duas International House em Coimbra. São escolas diferentes?"
                >
                    <>
                        <p>
                        Somos uma só escola, com duas localizações: Santa Clara e Santo António dos Olivais. <br />
                        Temos muito orgulho em sermos uma só equipa e, independentemente da escola que visite ou contacte, poderemos fornecer a informação de que necessita e ajudar com qualquer assunto referente à IH Coimbra - OSC. Por outro lado, as equipas administrativa, pedagógica e docente trabalham em conjunto para lhe prestar o melhor serviço possível, podendo encontrar membros da direção e professores em qualquer das escolas.
                        </p>
                    </>
                </Accordion>


                <h1 className={ Styles.bigTitle }>
                Ainda tem dúvidas?
                </h1>
            </div>

            <Contacts />
        </>
    );
};

export default FaqPage;