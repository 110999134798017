import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useMedia } from 'react-use-media';
import { take } from 'lodash';
import {
    Link,
} from 'react-router-dom';
import moment from 'moment';

import { Breakpoints } from '../../../constants';

import Styles from './News.module.css';
import AppStyles from '../../../App.module.css';

const News = () => {
    const mobile = useMedia({
        maxWidth: Breakpoints.MEDIUM,
    });

    const news = take(useSelector(state => state.posts.data), mobile ? 1 : 3);
    
    if (!news.length) {
        return null;
    }

    return (
        <div className={ classNames(Styles.news, AppStyles.container) }>
            <h1>
                Notícias
            </h1>
            <div className={ Styles.list }>
                { news.map((item) => (
                    <div key={ item.id } className={ Styles.item }>
                        <div className={ Styles.itemDate }>
                            <div>
                                { moment(item.date).format('D MMMM - YYYY') }
                            </div>
                        </div>
                        <div className={ Styles.itemTitle }>
                            {item.title}
                        </div>
                        <div className={ Styles.itemLink }>
                            <Link to={item.url}>
                                Ler mais
                            </Link>
                        </div>
                    </div>
                )) }
            </div>
        </div>
    );
};

export default News;
