import React from 'react';

import LevelItem from './LevelItem';

import Styles from './Levels.module.css';

const Levels = () => {
    return (
        <div className={ Styles.levels }>
            <h3>
                    Níveis
            </h3>

            <div className={ Styles.box }>
                <LevelItem 
                    grade="A1"
                    description="Cambridge Young Learners English"/>

                <LevelItem 
                    grade="A2"
                    description="Key for Schools"/>

                <LevelItem 
                    grade="B1"
                    description="Preliminary for Schools"/>

                <LevelItem 
                    grade="B2"
                    description="First for Schools"/>

                <LevelItem 
                    grade="C1"
                    description="Advanced"/>

                <LevelItem 
                    grade="C2"
                    description="Proficiency"/>
                
            </div>
        </div>
    );
};

export default Levels;
