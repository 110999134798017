import React from 'react';
import classNames from 'classnames';

import LineImage from '../../../images/rainbow-line.png';

import Styles from './Banner.module.css';
import AppStyles from '../../../App.module.css';

const Banner = () => {
    return (
        <>
            <div className={ Styles.banner }>
                <div className={ classNames(Styles.box, AppStyles.container) }>
                    <p>
                    Bem-vindo à
                    </p>
                    <h1>
                    International House
                    </h1>
                    <p>
                    Coimbra - Olivais e Santa Clara
                    </p>
                    <div className={ Styles.blocks }>
                        <div className={ Styles.blockItem }>
                            <span>
                            Cursos para todas as idades
                            </span>  
                        </div>
                        <div className={ Styles.blockItem }>
                            <span>
                            Exames de Cambridge
                            </span>
                        </div>
                        <div className={ Styles.blockItem }>
                            <span>
                            Inglês Técnico para a sua área de trabalho
                            </span>
                        </div>
                        <div className={ Styles.blockItem }>
                            <span>
                            Business English – empresarial e individual
                            </span>
                        </div>
                        <div className={ Styles.blockItem }>
                            <span>
                            Aulas individuais e à sua medida
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={ Styles.line }>
                <img src={ LineImage } alt=""/>
            </div>
        </>
    );
};

export default Banner;
