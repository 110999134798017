import React from 'react';
import { scroller } from 'react-scroll';
import { useHistory } from 'react-router-dom';

import { Routes } from '../../../constants';

import IconArrow from '../../../images/arrow-right.svg';

import Styles from './Callout.module.css';

const CalloutLink = ({ children }) => {
    let history = useHistory();

    function goToHomeContacts() {
        history.push(Routes.HOME);
        setTimeout(() => {
            scroller.scrollTo('contacts', {
                // duration: 1500,
                // delay: 100,
                smooth: true,
                // offset: 50, // Scrolls to element + 50 pixels down the page
            }); 
        }, 100);
    }

    return (
        <div className={ Styles.calloutLink }>
            <a 
                className={ Styles.action }
                role="button"
                tabIndex="0"
                onClick={ goToHomeContacts }>
                    Contacte-nos
                <img src={ IconArrow } alt=""/>
            </a>
        </div>
    );
};

export default CalloutLink;