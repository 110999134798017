import React from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const Seo = ({ pathname }) => {
    const pages = useSelector(state => state.pages.data);
    const page = pages.find((item)=>item.slug === pathname);
    
    if (!page) {
        return null;
    }

    const title = page.seoTitle || '';
    const description = page.seoDescription || '';

    return (
        <Helmet>
            <title>IHC - {title}</title>
            <meta name="description" content={ description } />
        </Helmet>
    );
};

Seo.propTypes = {
    pathname: PropTypes.string.isRequired,
};

export default Seo;