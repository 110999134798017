import React from 'react';
import classNames from 'classnames';
import { Element } from 'react-scroll';

import IconPhone from '../../../images/icon-phone.svg';
import IconFb from '../../../images/icon-fb.svg';
import IconIg from '../../../images/icon-ig.svg';
import IconEnvelope from '../../../images/icon-envelope.svg';
import IconLocation from '../../../images/icon-location.svg';

import Styles from './Contacts.module.css';
import AppStyles from '../../../App.module.css';

const Contacts = () => {
    return (
        <div className={ classNames(Styles.contacts, AppStyles.container) }>
            <div className={ Styles.main }>
                <Element name="contacts" />
                <h1>
                    Contactos
                </h1>
                <div className={ Styles.contactLine }>
                    <div className={ Styles.icon }>
                        <img src={ IconPhone } alt=""/>
                    </div>
                    <div className={ Styles.text }>
                        <a href="tel:+239440330">
                            239 440 330
                        </a>
                    </div>
                </div>
                <div className={ Styles.contactLine }>
                    <div className={ Styles.icon }>
                        <img src={ IconFb } alt=""/>
                    </div>
                    <div className={ Styles.text }>
                        <a
                            href="https://www.facebook.com/IHCOSC"
                            rel="noopener noreferrer"
                            target="_blank">
                            fb.com/IHCOSC
                        </a>
                    </div>
                </div>
                <div className={ Styles.contactLine }>
                    <div className={ Styles.icon }>
                        <img src={ IconIg } alt=""/>
                    </div>
                    <div className={ Styles.text }>
                        <a 
                            href="https://www.instagram.com/ih_cosc/"
                            rel="noopener noreferrer"
                            target="_blank">
                            ih_cosc
                        </a>
                        &nbsp;&nbsp;|&nbsp;&nbsp;
                        <a
                            href="https://www.instagram.com/explore/tags/ihcosc/"
                            rel="noopener noreferrer"
                            target="_blank">
                            #ihcosc
                        </a>
                    </div>
                </div>
            </div>
            <div className={ Styles.other }>
                <h1>
                    Santa Clara
                </h1>
                <div className={ Styles.contactLine }>
                    <div className={ Styles.icon }>
                        <img src={ IconEnvelope } alt=""/>
                    </div>
                    <div className={ Styles.text }>
                        <a href="mailto:info.sc@ihcoimbra-osc.com">
                            <span>
                                info.sc@ihcoimbra-osc.com
                            </span>
                        </a>
                    </div>
                </div>
                <div className={ Styles.contactLine }>
                    <div className={ Styles.icon }>
                        <img src={ IconLocation } alt=""/>
                    </div>
                    <div className={ Styles.text }>
                        <span className={ Styles.address }>
                            Rua António Augusto Gonçalves, 47 <br/>
                            3040-241 Coimbra <br/>
                            Portugal
                        </span>
                    </div>
                </div>
                <div className={ Styles.contactLine }>
                    <div></div>
                    <div className={ Styles.link }>
                        <a
                            href="https://maps.google.com/maps?daddr=Rua+Ant%C3%B3nio+Augusto+Gon%C3%A7alves,+47-51+3040-+241+Coimbra+Portugal&amp;saddr=&amp;f=d&amp;hl=de&amp;ie=UTF8&amp;z=16"
                            rel="noopener noreferrer"
                            target="_blank">
                            Ver direções
                        </a>
                    </div>
                </div>
            </div>
            <div className={ Styles.other }>
                <h1>
                    Olivais
                </h1>
                <div className={ Styles.contactLine }>
                    <div className={ Styles.icon }>
                        <img src={ IconEnvelope } alt=""/>
                    </div>
                    <div className={ Styles.text }>
                        <a href="mailto:info.o@ihcoimbra-osc.com">
                            <span>
                                info.o@ihcoimbra-osc.com
                            </span>
                        </a>
                    </div>
                </div>
                <div className={ Styles.contactLine }>
                    <div className={ Styles.icon }>
                        <img src={ IconLocation } alt=""/>
                    </div>
                    <div className={ Styles.text }>
                        <span className={ Styles.address }>
                            Rua Brigadeiro Correia Cardoso, 10 <br/>
                            3000-005 Coimbra <br/>
                            Portugal
                        </span>
                    </div>
                </div>
                <div className={ Styles.contactLine }>
                    <div></div>
                    <div className={ Styles.link }>
                        <a
                            href="https://maps.google.com/maps?daddr=Rua+Brigadeiro+Correia+Cardoso,+10+3000-+005+Coimbra+Portugal&amp;saddr=&amp;f=d&amp;hl=de&amp;ie=UTF8&amp;z=16"
                            rel="noopener noreferrer"
                            target="_blank">
                            Ver direções
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contacts;
